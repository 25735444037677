

































import {Component, Vue} from 'vue-property-decorator'
import CoachApi from '@/api/coach';

@Component({})
export default class CoachInfo extends Vue {
  private api = new CoachApi()
  private coachInfo = {}

  get coachId() {
    return this.$route.query.coachId;
  }

  private async activated() {
    await this.getInfo()
  }

  private async getInfo() {
    if (this.coachId) {
      this.coachInfo = await this.api.getCoachInfoByIdAsync(this.coachId)
    }
  }

  private async handleBuyCourse(item: any) {
    if (this.coachId) {
      await this.$router.push({
        path: '/pt-buy',
        query: {
          coachId: this.coachId,
          courseId: item.id
        }
      })
    }
  }
}
